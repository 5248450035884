export type UserData = {
  id: number
  username: string
  username_slug: string
  first_name: string
  last_name: string
  bio: string
  is_browsable: boolean
  avatars: object
  email: string
  email_verified: boolean
  links: object
}

export type LinkType = {
  id: string
  link: string
}

class User {
  private data: UserData

  constructor(data: UserData) {
    this.data = data
  }

  get id() {
    return this.data.id
  }

  get username() {
    return this.data.username
  }

  get username_slug() {
    return this.data.username_slug
  }

  get first_name() {
    return this.data.first_name
  }

  get last_name() {
    return this.data.last_name
  }

  get bio() {
    return this.data.bio
  }

  get is_browsable() {
    return this.data.is_browsable
  }

  get avatars() {
    return this.data.avatars
  }

  get email() {
    return this.data.email
  }

  get email_verified() {
    return this.data.email_verified
  }

  get links() {
    return this.data.links
  }

  getFullName() {
    return this.data.first_name + ' ' + this.data.last_name
  }

  getUsername() {
    return '@' + this.data.username
  }

  getHiddenLastName() {
    return this.data.first_name + ' ' + this.data.last_name.charAt(0)
  }

  getAvatar(size: 50 | 100 | 150 | 200 | 400) {
    return this.data.avatars[size]
  }
}

export default User
