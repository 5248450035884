import { useEffect, useCallback } from 'react'
import { useAtom } from 'jotai'

// Utils
import { userData, initData } from './atoms'
import User from '../../types/User'
import api from '../../apis'
import { notificationUnknownError } from '../../utils/notification'
import useAsync from '../../hooks/useAsync'

const useAuth = () => {
  const [data, setData] = useAtom(userData)
  const { isLoaded } = data

  const fetchMe = useCallback(async () => api.auth.getMe(), [])
  const { execute, status, response, error } = useAsync(fetchMe, false)

  /**
   * Trigger fetch me only if it's not in global state
   */
  useEffect(() => {
    if (isLoaded === false) {
      execute()
    }
  }, [isLoaded, execute])

  /**
   * Handle fetch me error
   */
  useEffect(() => {
    if (error && status === 'error') {
      error.handleDefault()
    }
  }, [error, status])

  /**
   * Handle fetch me success
   */
  useEffect(() => {
    if (response && status === 'success') {
      const { isGuest, user } = response.data

      setData({
        isLoaded: true,
        isGuest,
        user: isGuest ? null : new User(user),
      })
    }
  }, [status, response, setData])

  const logout = useCallback(async () => {
    try {
      const { status } = await api.auth.postSignOut()

      if (status === 204) {
        setData({
          ...initData,
          isLoaded: true,
        })
      } else {
        notificationUnknownError()
      }
    } catch (error) {
      error.handleDefault()
    }
  }, [setData])

  return {
    ...data,
    reloadData: execute,
    logout,
  }
}

export default useAuth
