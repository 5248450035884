import { useEffect, useState, useCallback } from 'react'

// @see https://usehooks.com/useAsync/

// Hook
const useAsync = (asyncFunction, immediate = true) => {
  const [data, setData] = useState({
    status: 'idle',
    response: null,
    error: null,
  })

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setData({
      status: 'pending',
      response: null,
      error: null,
    })
    return asyncFunction()
      .then((response) => {
        setData({
          status: 'success',
          response: response,
          error: null,
        })
      })
      .catch((error) => {
        setData({
          status: 'error',
          response: null,
          error: error,
        })
      })
  }, [asyncFunction])
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])

  return {
    ...data,
    execute,
  }
}

export default useAsync
