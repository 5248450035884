import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ROUTES from '../../config/routes'
import { useTranslation } from 'react-i18next'
import GatsbyLink from '../Link/GatsbyLink'

type FooterProps = {
  className: any
  hideDateAndCompany: boolean
  isCentered: boolean
}

const Footer = ({ className, hideDateAndCompany, isCentered }: FooterProps) => {
  const { t } = useTranslation('common')

  const separator = <span className={'px-1 hidden sm:block'}>{'·'}</span>

  return (
    <footer className={classnames('w-full mx-auto', className)}>
      <div
        className={classnames({
          'flex flex-col py-6': true,
          'items-center justify-center align-center': isCentered,
        })}
      >
        <div
          className={'flex flex-col sm:flex-row text-gray-700 text-xs tracking-tight text-center'}
        >
          {hideDateAndCompany !== true && (
            <>
              <span>
                © {new Date().getFullYear()} {t('footer.companyName')}
              </span>

              {separator}
            </>
          )}

          <GatsbyLink className={'py-1 sm:py-0'} to={ROUTES.terms}>
            {t('footer.termsLink')}
          </GatsbyLink>

          {separator}

          <GatsbyLink className={'py-1 sm:py-0'} to={ROUTES.privacy}>
            {t('footer.privacyLink')}
          </GatsbyLink>

          {separator}

          <GatsbyLink className={'py-1 sm:py-0'} to={ROUTES.cookies}>
            {t('footer.cookiesLink')}
          </GatsbyLink>

          {separator}

          <GatsbyLink className={'py-1 sm:py-0'} to={ROUTES.ourStory}>
            {t('footer.ourStoryLink')}
          </GatsbyLink>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.any,
  hideDateAndCompany: PropTypes.bool,
  isCentered: PropTypes.bool,
}

Footer.defaultProps = {
  isCentered: true,
}

export default Footer
